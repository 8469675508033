<template>
    <DashboardLayout>
        <div class="content-area__body">
            <section class="candidates-section section-gap-30">
                <div class="d-flex flex-wrap align-items-start justify-content-between">
                    <div class="back-button mt-0">
                        <router-link class="back-button__icon" :to="{name : 'jobs.published'}">
                            <i class="eicon e-back"></i>
                        </router-link>
                        <div class="back-button__text">{{ $t("Back To Jobs") }}
                            <v-popover offset="10" :disabled="false" style="display: initial;">
                                <button class="ej__tooltip__button mlF-1"><i class="eicon e-question"></i></button>
                                <template slot="popover">
                                    <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                    <a v-close-popover
                                       href="https://easy.jobs/docs/assign-candidates-for-assessment-in-easy-jobs"
                                       target="_blank">
                                        {{ $t(`How to assign candidates for assessment?`) }}
                                    </a>
                                </template>
                            </v-popover>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <button v-if="canOrganizeCandidate" class="button info-button ml-4 mt-0 mb-2" @click="showAssessmentModal()">
                            {{ $t(`Assign assessment`) }}
                        </button>
                        <div class="dropdown language-control">
                            <a href="#" class="button info-button dropdown-toggle ml-4 mt-0 mb-2" data-toggle="dropdown"
                               aria-haspopup="true" aria-expanded="false">
                                {{ $t(`More`) }}
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" style="font-size: 12px;">
                                <ul>
                                    <li v-if="canOrganizeCandidate">
                                        <a href="#" @click.prevent="showModal()">
                                            {{ $t(`Invite candidate`) }}
                                        </a>
                                    </li>
                                    <li v-if="canOrganizeCandidate">
                                        <router-link :to="{ name : 'job.pipeline', params: { jobId } }">
                                            {{ $t(`Pipeline`) }}
                                        </router-link>
                                    </li>
                                    <li v-if="canViewCandidate">
                                        <a href="#" @click.prevent="downloadUrl" target="_blank">
                                            {{ $t(`Export`) }}
                                        </a>
                                    </li>
                                    <li v-if="canDeleteCandidate">
                                        <a href="#" @click.prevent="removeCandidates" target="_blank">
                                            {{ $t(`Remove candidates`) }}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" @click.prevent="showPendingCandidates" target="_blank">
                                            {{ $t(`Pending applications`) }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section-title-wrap" v-if="job">
                    <div class="mt-1 mr-2">
                        <div class="section-title">{{ job.title }}</div>
                        <p class="section-label">{{ job.created_at }}</p>
                    </div>
                    <form action="" @submit.prevent=""
                          class="form-filter mb-0 mt-1 d-flex flex-grow-1 justify-content-end">
                        <div class="select-option" style="min-width: 200px">
                            <select v-model="sortBy" @change="doSorting()" style="width: auto;">
                                <option value="">{{ $t('Sort candidates') }}</option>
                                <option v-for="(val, key) in sortingParam" :value="key">{{ $t(val) }}</option>
                            </select>
                        </div>
                        <div class="search-bar mr-0 candidate-filter-searchbar"><input v-model="search"
                                                                                       @keyup="getCandidates"
                                                                                       type="text"
                                                                                       :placeholder="$t('search candidates name')"/>
                        </div>
                        <button data-btn="true"
                                class="button info-button dropdown-toggle candidate-filter-toggle-button"
                                @click.prevent="filter=!filter">
                            <span data-btn="true">Filter</span>
                        </button>
                    </form>
                </div>
                <div class="candidates-box position-relative" v-on-click-away="hideFilter">
                    <div class="candidates-filter-box" :class="{'show': filter}">
                        <div class="search-bar mr-0"><input v-model="search" @keyup="getCandidates" type="text"
                                                            :placeholder="$t('search candidates name')"/></div>
                        <div class="filter-card-wrapper">
                            <div class="filter-card">
                                <div class="filter-card__heading gutter-10">
                                    <div class="section-title">{{ $t("Filter") }}</div>
                                </div>
                                <div class="filter-card__body gutter-10 text-break">
                                    <ul>
                                        <li v-for="(filter, index) in filters">
                                            <label class="checkbox">
                                                <input type="checkbox" @change="filterCandidates" v-model="filter.isChecked"
                                                        @click="filter.isChecked=!filter.isChecked"/>
                                                <span>{{ $t(filter.title) }}</span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="filter-card">
                                <div class="filter-card__heading gutter-10">
                                    <div class="section-title">{{ $t("Filter By Stage") }}</div>
                                </div>
                                <div class="filter-card__body gutter-10 text-break">
                                    <ul>
                                        <li v-for="stage in pipelines">
                                            <label class="checkbox">
                                                <input type="checkbox" @change="filterCandidates"
                                                       v-model="stage.isChecked" @click="stage.isChecked=!stage.isChecked"/>
                                                <span>{{ $t(stage.name) }}</span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- data table -->

                    <div class="data-table candidates-table">
                        <TableLoader v-if="isContentLoading"></TableLoader>
                        <div class="table-wrap" v-else>
                            <h4 class="empty-message" v-if="isEmpty">{{ $t("No Candidates Found") }}</h4>
                            <div class="table" v-else>
                                <div class="table__row table__head">
                                    <div class="table-cell text-capitalize" style="width: 10px;">
                                        <label class="checkbox" for="select-all">
                                            <input type="checkbox" @change="selectAll" v-model="allSelected"
                                                   id="select-all">
                                            <span></span>
                                        </label>
                                    </div>
                                    <div class="table-cell text-capitalize">{{ $t("Name") }}</div>
                                    <div class="table-cell text-capitalize" v-if="ai_score_show">
                                        {{ $t("Score") }}
                                    </div>
                                    <div class="table-cell text-capitalize candidate-apply-time">{{ $t("Date") }}</div>
                                    <div class="table-cell text-capitalize" v-if="showAssessmentColumn">
                                        {{ $t("Assessments") }}
                                    </div>
                                    <div class="table-cell text-capitalize center-center">{{ $t("stage") }}</div>
                                    <div class="table-cell text-capitalize">{{ $t("Ratings") }}</div>
                                </div>
                                <div class="table__row" v-for="(applicant, index)  in candidates">
                                    <div class="table-cell">
                                        <label class="checkbox" :for="`checkbox-${applicant.id}`">
                                            <input type="checkbox" @change="selectApplicant(applicant, index)"
                                                   v-model="applicant.isSelected" :id="`checkbox-${applicant.id}`">
                                            <span></span>
                                        </label>
                                    </div>
                                    <div class="table-cell user__info">

                                        <router-link class="d-flex align-items-center"
                                                     :to="{name : 'applicant.details', params: {id :applicant.id}}">
                                            <div class="user__image">
                                                <img :src="applicant.image" @error="loadApplicantErrorImage(applicant)"
                                                     alt="" class="w-100 img-fluid">
                                            </div>
                                            <h4 class="user__name">{{ applicant.user.name }}</h4>
                                        </router-link>
                                    </div>
                                    <div class="table-cell" v-if="ai_score_show">
                                        <ai-score-progress-bar
                                                class="mb-1"
                                                :sort-by="sortBy"
                                                :applicant="applicant"
                                        />
                                    </div>
                                    <div class="table-cell candidate-apply-time">
                                        {{ applicant.submitted_at }}
                                    </div>
                                    <div class="table-cell center-center" v-if="showAssessmentColumn">
                                        <a href="#" class="d-flex flex-wrap" v-if="applicant.assessments.length>0"
                                           @click.prevent="showApplicantAssessment(applicant)">
                                            <span class="label label-primary mr-1 mt-1"
                                                  :class="applicant.assessments[0].class_name"
                                                  :title="applicant.assessments[0].name">{{
                                                    applicant.assessments[0].name | shortText
                                                }}</span>
                                            <span v-if="applicant.assessments.length>1"
                                                  class="label label-primary mr-1 mt-1">+{{
                                                    applicant.assessments.length - 1
                                                }}</span>
                                        </a>
                                    </div>
                                    <div class="table-cell job__status applicant_ej_job_status center-center">
                                        <div class="dropdown"  :class="{showMenu: applicant.show_menu}" :id="`dd-${applicant.id}`">
                                            <a href="javascript:void(0)"
                                               class="semi-button h-modified w-100 dropdown-toggle" :class="{
                                                'danger-label' : applicant.pipeline.rejected,
                                                'success-label' : applicant.pipeline.selected,
                                                'primary-label' : applicant.pipeline.other,
                                                }"
                                               :title="applicant.pipeline.name"
                                               @click="showPipelines(index)" :id="`dd-a-${applicant.id}`">{{
                                                    $t(applicantPipelineName(applicant.pipeline.name))
                                                }}

                                            </a>
                                            <ul class="dropdown-menu dd-candidate">
                                                <template v-for="pipe in job.pipeline">
                                                    <a class="dropdown-item text-break dropdown-link"
                                                       href="javascript:void(0);"
                                                       v-if="applicant.pipeline.id != pipe.id && !selected(pipe.id) && !isRemoteInterview(pipe.id)"
                                                       @click="changePipeline(applicant, pipe,index)"
                                                       :class="{ 'text-danger' : rejected(pipe.id), 'text-success' : selected(pipe.id) }"
                                                    >{{ $t(pipe.name) }}</a>
                                                    <a class="dropdown-item text-break" href="javascript:void(0);"
                                                       v-if="applicant.pipeline.id != pipe.id && selected(pipe.id)"
                                                       @click="onboardCandidate(applicant)"
                                                       :class="{ 'text-danger' : rejected(pipe.id), 'text-success' : selected(pipe.id) }"

                                                    >{{ $t(pipe.name) }}</a>
                                                    <a class="dropdown-item text-break" href="javascript:void(0);"
                                                       v-if="applicant.pipeline.id != pipe.id && isRemoteInterview(pipe.id)"
                                                       @click="selectedForRemoteInterview(applicant)"
                                                       :class="{ 'text-danger' : rejected(pipe.id), 'text-success' : selected(pipe.id) }"
                                                    >{{ $t(pipe.name) }}</a>
                                                </template>

                                                <!--                                                <li class="dropdown-item  dropdown-link" v-for="pipe in job.pipeline"-->
                                                <!--                                                    v-if="applicant.pipeline.id != pipe.id"-->
                                                <!--                                                    @click="changePipeline(applicant, pipe,index)"> {{ pipe.name }}-->
                                                <!--                                                </li>-->
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="table-cell user-rate">
                                        <rating v-if="loadRating" :rate="applicant.rating" :key="index"
                                                :applicant="applicant"></rating>
                                    </div>
                                </div>
                            </div>
                            <pagination v-if="!isEmpty" :pagination="paginationData"
                                        @paginate="paginateTo"></pagination>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <modal-add-candidate-to-job :job="job" v-if="addCandidateModal && canOrganizeCandidate" :job-id="jobId" @closeModal="closeModal()"
                                    @candidateAdded="candidateAdded"></modal-add-candidate-to-job>
        <manage-assessment v-if="manageAssessment && canOrganizeCandidate" :selectedApplicants="selectedApplicants"
                           @closeModal="manageAssessment = false" :job="jobId"
                           @assessmentUpdated="assessmentAdded"></manage-assessment>
        <modal-pending-candidates v-if="pendingCandidates" @closeModal="pendingCandidates = false"
                                  :job-id="jobId"></modal-pending-candidates>
        <modal-onboarding
                v-if="showOnboardingModal"
                :applicant-ids="[candidateId]"
                :job-id="jobId"
                @closeModal="closeOnboardingModal"
                @afterComplete="movedToSelected"
        />
        <remote-interview
                v-if="showRemoteInterview"
                :applicant-ids="[candidateId]"
                :job-id="jobId"
                @remote_interview_closed="closeRemoteInterview"
                @remote_interview_moved="movedToRemoteInterview"
        ></remote-interview>
    </DashboardLayout>
</template>
<script>
import {mapState} from "vuex";
import client from "../../app/api/Client";
import {DEFAULT_PIPELINE, FILTER, PERMISSIONS, STATUS} from "../../constants/enums";
import {EventBus} from "../../extra/event-bus";
import {CANDIDATE_RATING_ADDED} from "../../constants/events";
import {CANDIDATE_SORTING} from '../../extra/constants'
import {queryUrl} from "../../extra/helper";
import {debounce} from "lodash";
import {canDeleteCandidate, canOrganizeCandidate, canViewCandidate} from "../../config/permission";

const Pagination = () => import("../../components/pagination/Pagination");
const DashboardLayout = () => import("../../layouts/DashboardLayout.vue");
const Rating = () => import("../../components/common/Rating.vue");
const TableLoader = () => import("../../components/_loaders/_TableLoader.vue");
const ModalAddCandidateToJob = () => import("../../components/modal/job/AddCandidateToJob.vue");
const ModalPendingCandidates = () => import("../../components/modal/job/PendingCandidates.vue");
const AiScoreProgressBar = () => import("../../components/jobs/AiScoreProgressBar");
const ManageAssessment = () => import("../../components/modal/job/ManageAssessment");
const ModalOnboarding = () => import("../../components/modal/job/Onboarding.vue");
const RemoteInterview = () => import("../../components/modal/job/RemoteInterview.vue");

export default {
    components: {
        AiScoreProgressBar,
        DashboardLayout,
        Rating,
        TableLoader,
        ModalAddCandidateToJob,
        ModalPendingCandidates,
        ManageAssessment,
        Pagination,
        ModalOnboarding,
        RemoteInterview
    },
    data() {
        return {
            selectedPipeline: null,
            showOnboardingModal: false,
            showRemoteInterview: false,
            candidateId: null,
            manageAssessment: false,
            selectedApplicants: [],
            sortBy: "",
            isContentLoading: false,
            jobId: this.$route.params.jobId,
            candidates: [],
            pipelines: [],
            STATUS,
            candidate: null,
            job: null,
            loadRating: true,
            filters: [
                {
                    value: FILTER.JOB_CANDIDATES.NEW,
                    title: `New`,
                    isChecked: false
                },
                {
                    value: FILTER.JOB_CANDIDATES.RATED,
                    title: `rated`,
                    isChecked: false
                },
                {
                    value: FILTER.JOB_CANDIDATES.NO_RATED,
                    title: `no rated`,
                    isChecked: false
                },
                {
                    value: FILTER.JOB_CANDIDATES.ASSIGNED_ASSESSMENT,
                    title: `with assessment`,
                    isChecked: false
                },
                {
                    value: FILTER.JOB_CANDIDATES.UN_ASSIGNED_ASSESSMENT,
                    title: 'without assessment',
                    isChecked: false
                },
            ],
            search: "",
            allSelected: false,
            addCandidateModal: false,
            pendingCandidates: false,
            filter: false,
            paginationData: null,
            ai_score_show: false
        }
    },
    computed: {
        ...mapState(['company']),
        sortingParam() {
            let data = CANDIDATE_SORTING;
            let filter = [];
            if (!this.ai_score_show) {
                filter.push('SORT_BY_TOTAL_AI_SCORE');
            }

            if (!this.job.show_quiz_filter) {
                filter.push('SORT_BY_QUIZ_SCORE');
            }

            if (filter.length) {
                data = _.filter(data, (value, key) => {
                    return !filter.includes(key)
                });
            }

            return data;
        },
        isEmpty() {
            return this.candidates.length === 0;
        },
        candidateData() {
            return this.candidates;
        },
        showAssessments() {
            return this.selectedApplicants.length > 0;
        },
        showAssessmentColumn() {
            let hasCandidateAssessments = this.candidates.filter(c => c.assessments.length > 0)
            return hasCandidateAssessments.length !== 0;
        },
        params() {
            let basicFilter = this.filters.filter(f => f.isChecked).map(f => f.value) || [];
            let pipelineFilter = this.pipelines.filter(f => f.isChecked).map(f => _.toString(f.index)) || [];
            return {
                search: this.search,
                basic: basicFilter,
                pipeline: pipelineFilter,
                sortBy: this.sortBy,
                page: 1
            }
        },
        canOrganizeCandidate() {
            return canOrganizeCandidate();
        },
        canViewCandidate() {
            return canViewCandidate();
        },
        canDeleteCandidate() {
            return canDeleteCandidate();
        },
    },
    filters: {
        shortText: function (value) {
            if (!value) return '';
            if (value === undefined) return '';
            if (value.length > 8) {
                return value.slice(0, 8) + '..';
            }
            return value;
        }
    },
    methods: {
        async applicantExists(applicant) {
            try {
                await client().get(`/job/${applicant.id}/exists-test`);
                return true;
            } catch ($e) {
                this.$toast.error(this.$t(`Applicant not found in this job.`));
                await this.getCandidateData(this.getQueryData());
                return false;
            }

        },
        hideAllPipelineMenu() {
            this.candidates = this.candidates.map(c => {
                c.show_menu = false;
                return c;
            })
        },
        showPipelines(index) {
            if (!this.company.permission[PERMISSIONS.CANDIDATE_ORGANIZE]) {
                return false;
            }

            this.candidates = this.candidates.map((c, ind) => {
                if (ind === index) {
                    c.show_menu = !c.show_menu;
                } else {
                    c.show_menu = false;
                }
                return c;
            })


        },
        async changePipeline(applicant, pipeline, index) {
            if (!await this.applicantExists(applicant)) {
                return false;
            }

            await this.moveCandidateToPipeline(applicant, pipeline, index);
        },
        async onboardCandidate(applicant) {
            if (!await this.applicantExists(applicant)) {
                return false;
            }

            this.candidateId = applicant.id;
            this.showOnboardingModal = true;
        },
        async movedToSelected() {
            let applicant = this.candidates.find(c => c.id = this.candidateId);
            let selectedPipeline = this.job.pipeline.find(p => p.id == DEFAULT_PIPELINE.SELECTED);
            let index = this.candidates.indexOf(applicant);
            this.closeOnboardingModal();
            await this.moveCandidateToPipeline(applicant, selectedPipeline, index);
        },
        async movedToRemoteInterview(pipeline) {
            await this.getCandidateData(this.getQueryData());
            await this.closeRemoteInterview();
        },
        async selectedForRemoteInterview(applicant) {
            if (!await this.applicantExists(applicant)) {
                return false;
            }

            this.candidateId = applicant.id;
            this.showRemoteInterview = true;
        },
        closeRemoteInterview() {
            this.candidateId = null;
            this.showRemoteInterview = false;
            this.hideAllPipelineMenu();
        },
        closeOnboardingModal() {
            this.candidateId = null;
            this.showOnboardingModal = false;
            this.hideAllPipelineMenu();
        },
        selected(pipelineId) {
            return pipelineId === DEFAULT_PIPELINE.SELECTED;
        },
        rejected(pipelineId) {
            return pipelineId === DEFAULT_PIPELINE.REJECTED;
        },
        isRemoteInterview(pipelineId) {
            return pipelineId === DEFAULT_PIPELINE.REMOTE_INTERVIEW;

        },
        async moveCandidateToPipeline(applicant, pipeline, index) {
            if (this.company.permission[PERMISSIONS.CANDIDATE_ORGANIZE]) {
                try {
                    let {
                        data: {
                            data,
                            message
                        }
                    } = await client().post(`/job/${this.jobId}/pipeline/candidate`, {
                        applicants: [applicant.id],
                        pipeline: pipeline.id
                    });
                    this.$toast.success(this.$t(message));
                    this.hideAllPipelineMenu();
                    await this.getCandidateData(this.getQueryData());
                } catch (error) {
                    this.$toast.error(this.$t(error.response.data.message));
                    if (error.response.status === 480 || error.response.status === 490) {
                        // this.changePipeline(this.activeStagxe);
                    }
                }
            } else {
                this.$toast.error(this.$t(`Unauthorized access`));
            }
        },
        applicantPipelineName(name) {
            if(name && name.length > 9) {
                name.substring(0, 9);
            }
            return name;

        },
        hideFilter(event){
            if(!event.target.getAttribute('data-btn')) {
                this.filter = false;
            }
        },
        async paginateTo(page) {
            if (typeof page === 'undefined') page = 1;
            this.params.page = page;
            await this.$router.push({path: queryUrl(`/job/${this.jobId}/candidate`, this.params)});
            await this.getCandidateData(this.params);
        },
        showPendingCandidates() {
            this.pendingCandidates = true;
        },
        async removeCandidates() {
            if (this.selectedApplicants.length === 0) {
                this.$toast.error(this.$t('Select candidates to remove.'));
                return 0;
            }
            let confirm = await this.$dialog.confirm({
                title: this.$t(`Confirmation`),
                body: this.$t(`Are you sure, you want to remove this candidate's job application?`)
            });
            if (confirm) {
                try {
                    let {data: {data}} = await client().post(`/job/${this.jobId}/candidate/delete`, {candidates: this.selectedApplicants});
                    this.candidate = data;
                    this.selectedApplicants = [];
                    this.checkAllCandidateSelected();
                } catch (err) {
                } finally {
                    await this.getCandidateData(this.getQueryData());
                }
            }
        },
        downloadUrl() {
            if (this.candidates.length === 0) {
                this.$toast.error(this.$t('No candidates to export'));
                return 0;
            }
            let url = `/job/${this.jobId}/candidate/export`;
            let params = {...this.params};
            params.token = this.$store.state.token;
            window.open(queryUrl(url, params), '_blank');
        },
        assessmentAdded() {
            this.$toast.success(this.$t(`Candidate(s) added to assessments.`));
            this.selectedApplicants = [];
            this.filterCandidates();
            this.allSelected = false;
            this.checkAllCandidateSelected();
        },
        checkAllCandidateSelected() {
            this.allSelected = this.candidates.length === this.selectedApplicants.length;
        },
        showApplicantAssessment(applicant) {
            return this.$router.push({
                name: 'applicant.details',
                params: {id: applicant.id},
                query: {tab: 'evaluation-assessment'}
            });
        },
        showAssessmentModal() {
            let cannotAssignAssessmentApplicants = this.candidates.filter(c => (c.pipeline.rejected || c.pipeline.selected)).map(c => c.id);
            let hasInvalidCandidates = cannotAssignAssessmentApplicants.some(caa => this.selectedApplicants.includes(caa));
            if (hasInvalidCandidates) {
                this.$toast.warning('Sorry, assessment cannot be assigned to Selected or Rejected candidates. Please try again.');
                this.candidates = this.candidates.map(candidate => {
                    if (candidate.pipeline.rejected || candidate.pipeline.selected) {
                        candidate.isSelected = false;
                        let alreadySelectedIndex = this.selectedApplicants.indexOf(candidate.id);
                        if (alreadySelectedIndex > -1) {
                            this.selectedApplicants.splice(alreadySelectedIndex, 1);
                        }
                    }
                    return candidate;
                });
            }
            this.checkAllCandidateSelected();
            if (!this.showAssessments) {
                this.$toast.error(this.$t('Please select some applicants.'));
                return false;
            }
            this.manageAssessment = true;
        },
        selectApplicant(applicant, index) {
            let alreadySelectedIndex = this.selectedApplicants.indexOf(applicant.id);
            if (alreadySelectedIndex > -1) {
                this.selectedApplicants.splice(alreadySelectedIndex, 1);
            }

            if (applicant.isSelected) {
                this.selectedApplicants.push(applicant.id);
            }
            this.checkAllCandidateSelected();
        },
        selectAll() {
            if (this.allSelected) {
                this.candidates = this.candidates.map(c => {
                    c.isSelected = true;
                    this.selectedApplicants.push(c.id);
                    return c;
                })
            } else {
                this.candidates = this.candidates.map(c => {
                    c.isSelected = false;
                    this.selectedApplicants = [];
                    return c;
                })
            }
            this.checkAllCandidateSelected();
        },
        async doSorting() {
            await this.filterCandidates();
        },
        candidateAdded(value) {
            if (!_.isEmpty(value)) {
                this.candidates.unshift(value);
            }
            this.addCandidateModal = false;
        },
        async showCandidateDetails(applicant) {
            try {
                let {data: {data}} = await client().get(`/job/applicant/${applicant.id}`);
                this.candidate = data;
            } catch (e) {
            }
        },
        async getCandidateData(queryData = null) {
            this.candidates = [];
            this.isContentLoading = true;
            try {
                let {
                    data: {
                        data: {
                            candidates,
                            job,
                            ai_score_show
                        }
                    }
                } = await client().get(`/job/${this.jobId}/candidates`, {params: queryData})

                this.ai_score_show = ai_score_show

                this.candidates = candidates.data;
                this.paginationData = candidates;
                this.job = job;
                let selectedPipelines = queryData['pipeline'];
                this.pipelines = _.map(job.pipeline, (pipeline) => {
                    let pipe = {
                        index: pipeline.id,
                        name: pipeline.name
                    }
                    if (_.isUndefined(selectedPipelines)) {
                        pipe.isChecked = false;
                    } else if (_.isArray(selectedPipelines)) {
                        pipe.isChecked = selectedPipelines.includes(_.toString(pipeline.id), false);
                    } else {
                        pipe.isChecked = selectedPipelines === _.toString(pipeline.id);
                    }
                    return pipe;
                });
                this.checkAllCandidateSelected();
            } catch (error) {
                this.$toast.error(error.response.data.message);
                if (error.response.status === 480 || error.response.status === 490) {
                    await this.$router.push({name: 'dashboard'});
                }
            }
            this.isContentLoading = false;
        },
        async filterCandidates() {
            await this.$router.push({path: queryUrl(`/job/${this.jobId}/candidate`, this.params)});
            await this.getCandidateData(this.params);
        },
        getCandidates: debounce(function () {
            this.filterCandidates();
        }, 1000),
        showModal() {
            this.addCandidateModal = true;
        },
        closeModal() {
            this.addCandidateModal = false;
            this.$router.push({name: 'job.candidates', params: {jobId: this.jobId}})
        },
        getQueryData() {
            let queryData = this.$router.currentRoute.query;
            if (_.isEmpty(queryData['page'])) {
                queryData['page'] = 1
            }

            if (!_.isUndefined(queryData['pipeline[]'])) {
                if (_.includes(_.keys(queryData), 'pipeline[]')) {
                    queryData['pipeline'] = _.clone(queryData['pipeline[]'], true);
                    delete queryData['pipeline[]'];
                }
            }
            if (!_.isUndefined(queryData['pipeline']) && _.isString(queryData['pipeline'])) {
                queryData['pipeline'] = [queryData['pipeline']];
            }
            if (_.includes(_.keys(queryData), 'search')) {
                this.search = queryData['search']
            }
            if (_.includes(_.keys(queryData), 'sortBy')) {
                this.sortBy = queryData['sortBy']
            }
            if (!_.isUndefined(queryData['basic[]'])) {
                if (_.includes(_.keys(queryData), 'basic[]')) {
                    queryData['basic'] = _.clone(queryData['basic[]'], true);
                    // delete queryData['basic[]'];
                }
                if (!_.isUndefined(queryData['basic']) && _.isString(queryData['basic'])) {
                    queryData['basic'] = [queryData['basic']];
                }

                this.filters = this.filters.map(filter => {
                    if (_.includes(queryData['basic'], _.toString(filter.value))) {
                        filter.isChecked = true;
                    }
                    return filter;
                })

            }

            return queryData;
        }

    },
    async mounted() {
        await this.getCandidateData(this.getQueryData());
        EventBus.$on(CANDIDATE_RATING_ADDED, (candidate) => {
            this.candidates = this.candidates.map((existingCandidate) => {
                if (candidate.id === existingCandidate.id) {
                    existingCandidate.rating = candidate.rating;
                }
                return existingCandidate;
            })
        });
        let self = this;
        document.querySelectorAll('.content-area__body').forEach(function(el){
            el.addEventListener('click', (el) => {
                if(!el.target.id) {
                    self.hideAllPipelineMenu()
                }
            });
        });
    },
    created() {
        this.addCandidateModal = !!this.$route.query.invite;
    },
    watch: {
        '$route': {
            handler: function(newValue, OldValue) {
                this.addCandidateModal = !!this.$route.query.invite;
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>
.back-link a {
    color: #597dfc;
    font-size: .9em;
    font-weight: 400;
}

.select-option {
    min-width: unset;
}

.select-option select {
    position: static;
}

.select-option:after {
    pointer-events: none;
}

.section-title {
    text-transform: unset;
}

.label-primary {
    background: rgba(89, 125, 252, 0.15);
    color: #597dfc;
    font-size: 0.725rem;
    border-radius: 50px;
    padding: 5px 6px;
    line-height: 1;
    font-weight: 500;
    display: inline-block;
}

.ended {
    background: rgba(96, 206, 131, 0.15) !important;
    color: #60ce83;

}

.not-completed {
    color: #ff5f74;
    background: rgba(255, 95, 116, 0.15) !important;
}

.assessment {
    cursor: pointer;
}

.checkbox input[type="checkbox"]:checked + span:before {
    color: #60ce83;
}

.checkbox input[type="checkbox"] + span {
    color: #7d8091;
    font-weight: 500;
}

.center-center {
    text-align: center !important;
}

.job__status .dropdown {
    /*cursor: pointer;*/
    position: relative;
}

.job__status .dropdown .dropdown-menu {
    width: 200px;
    box-shadow: 0 5px 10px rgb(125 128 145 / 30%);
    border: 0;
    padding: 7px;
    border-radius: 3.5px;
}

.job__status .dropdown .dropdown-menu .dropdown-item {
    padding: 5px 15px;
    font-size: 12px;
    color: #212529;
    font-weight: 500;
    white-space: break-spaces;
}

.job__status .dropdown .dropdown-menu .dropdown-item:hover {
    color: #597dfc;
    cursor: pointer;
}

.filter-card ul {
    list-style: none;
}
.language-control ul {
    list-style: none;
}

.showMenu ul {
    display: block;
}


</style>
